import React from 'react'
import { StaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import ServiceSlider from 'react-slick'
import { Link } from '@reach/router';
import { IoIosArrowRoundForward } from "react-icons/io"; 
import './services.scss'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  arrows:false,
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 700,
  slidesToShow: 4,
  slidesToScroll: 1,
  easing: 'linear',
  speed: 2000,
  responsive: [
      {
          breakpoint: 1136,
          settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
          }
      },
      {
          breakpoint: 992,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
          }
      },
      {
          breakpoint: 640,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          }
      }
  ]
};

const Services = () => (
    <StaticQuery
        query={graphql`
            query {
                allNodeServices(sort: {fields: field_weight, order: ASC}, filter: {status : {eq : true}}) {
                    edges {
                      node {
                        id
                        title
                        path{
                          alias
                        }
                        body {
                          value
                          format
                          processed
                          summary
                        }
                        relationships {
                          field_media_img {
                            relationships {
                              field_media_image {
                                localFile {
                                  childImageSharp {
                                    fixed(width: 573, height: 344) {
                                      src
                                    }
                                    original {
                                      width
                                      height
                                      src
                                   }

                                  }
                                }
                              }
                            }
                          }
                        }
                        relationships {
                          field_icon {
                            relationships {
                              field_media_image {
                                localFile {
                                  childImageSharp {
                                    fixed(width: 71, quality: 100) {
                                      src
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
            }
        `} 

        render={data => (
            <div className="wd-services">
                <div className="wd-box">
                    <h2 className="wd-box__title wd-box__title--1">nos services</h2>
                </div>
                <div className="wd-services__container">
                    <ServiceSlider {...settings} className="wd-services__slider">
                          {data.allNodeServices.edges.map(({ node }) => (
                              <Link to={node.path.alias} className="wd-services__button">
                                  <div className="wd-services__service" key={node.id}>
                                      {node.relationships.field_icon.relationships.field_media_image != null &&
                                      <Img className="wd-services__icon" fixed={node.relationships.field_icon.relationships.field_media_image.localFile.childImageSharp.fixed} />
                                      }
                                      {/*<h2 className="wd-services__title">{node.title}</h2>*/}
                                      
                                      
                                  </div>
                                <span>{node.title}</span>
                              </Link>
                          ))}
                    </ServiceSlider>
                </div>
                <div className="wd-box">
                    <Link to="/services" className="wd-button wd-button--1">
                        voir les services <IoIosArrowRoundForward className="wd-button__icon wd-button__icon--1"/> 
                    </Link>
                </div>
            </div>  
        )}
    />
)

export default Services;