import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import BlogSlider from 'react-slick'
import { Link } from '@reach/router';
import { IoIosArrowRoundForward } from "react-icons/io"; 

import './blog.scss'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
    arrows:false,
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    easing: 'linear',
    speed: 2000,
    responsive: [
        {
            breakpoint: 1136,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
  };

export default () => (
    <StaticQuery
        query={graphql`
        query {
            allNodeBlog (sort : {fields :created,order : DESC}){
                edges {
                    node {
                        path{
                            alias
                        }
                        nid
                        relationships{
                            field_blog_type{
                                    name
                                }
                            }
                        title
                        body {
                            value
                            format
                            processed
                            summary
                        }
                        relationships {
                            field_blog_type {
                                name
                            }
                        }
                        relationships{
                            field_img{
                                relationships{
                                    field_media_image{
                                        localFile{
                                            childImageSharp{
                                                fixed(width: 362, height: 200 , quality:90){
													src
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      `}
        render={data => (
            <div className="wd-blog">
                <div className="wd-blog__borders">
                    <div className="wd-box">
                        <h2 className="wd-box__title wd-box__title--2">Blog</h2>
                    </div>
                    <div className="wd-blog__container">
                        <BlogSlider {...settings} className="wd-blog__slider">
                                {data.allNodeBlog.edges.slice(0,3).map(({ node }) => (
                                <div className="wd-blog__blog">
                                    {node.relationships.field_img.relationships.field_media_image != null &&
                                        <Link to={node.path.alias}>
                                            <Img className="wd-blog__img" fixed={node.relationships.field_img.relationships.field_media_image.localFile.childImageSharp.fixed} />
                                        </Link>
                                    }
                                    <span className="wd-blog__term">{node.relationships.field_blog_type.name}</span>
                                    <h2 className="wd-blog__title">
                                        <Link to={node.path.alias}>{node.title}</Link>
                                    </h2>
                                    <div className="wd-blog__description" dangerouslySetInnerHTML={{ __html: node.body.summary }} />
                                    <Link to={node.path.alias} className=" wd-blog__link wd-button wd-button--3">
                                        Lire plus <IoIosArrowRoundForward className="wd-button__icon wd-button__icon--1"/> 
                                    </Link>
                                </div>
                                
                                ))}
                        </BlogSlider>
                    </div>
                    <div className="wd-box">
                        <Link to="/blog" className="wd-button wd-button--2">
                            Autres articles <IoIosArrowRoundForward className="wd-button__icon wd-button__icon--2"/> 
                        </Link>
                    </div>
                </div>
            </div>
        )}
    />
)