import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { MdClear} from "react-icons/md"; 
import { genericHashLink } from 'react-router-hash-link';
import GatsbyLink from 'gatsby-link';
import './mobileNav.scss'
import Logo from '../../img/wd-logo.svg';
import { Link } from "@reach/router";

const MyHashLink = (props) => genericHashLink(props, GatsbyLink);

const mobileNav = (props) => {

    const data = useStaticQuery(graphql`
        query {
        allMenuLinkContentMenuLinkContent (sort: {fields: [weight], order: ASC}, filter: {menu_name: {eq: "main"}, enabled : {eq : true}}){
                edges{
                    node {
                        id          
                        title
                        link {
                        uri
                        }
                    }
                }
        }
    }
    `)

    let mobileNavClasses = ['wd-mobile-nav'];

    if(props.showMobileNav) {
        mobileNavClasses = ['wd-mobile-nav', 'wd-mobile-nav--open'];
    }

    
    return(
        <nav className={mobileNavClasses.join(' ')}>
            <MdClear className="wd-mobile-nav__close" onClick={props.click}/>
            <ul className="wd-mobile-nav__list">
                {data.allMenuLinkContentMenuLinkContent.edges.map(({ node }) => (
                    <li key={node.id} className="wd-mobile-nav__item">
                        <a  className="wd-mobile-nav__link" href={node.link.uri.replace('internal:', '')}>{node.title}</a>
                    </li>
                ))}
                <li className="wd-mobile-nav__item">
                    <MyHashLink smooth to="/#wd-contact" className="wd-mobile-nav__link" onClick={props.click}>contact</MyHashLink>
                </li>
            </ul>
            <Link to="/" className="wd-mobile-nav__logo-link">
                <img className="wd-mobile-nav__logo" src={Logo} alt="Webodrop"/>
            </Link>
        </nav> 
    );
};

export default mobileNav

